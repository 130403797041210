<template>
  <div class="test">
    <div id="mic-test"></div>
    <!-- <router-link tag="button" style="margin:0 100px" class="el-button" type="primary" to="/"
      >返回首页</router-link
    > -->
    <div class="flex" id="items">
      <div
        class="item"
        :id="'item' + item.id"
        v-for="(item, index) in value"
        :key="index"
        v-html="item.content"
      ></div>
    </div>
  </div>
</template>
<style scope>
.item {
  width: 200px;
  height: 200px;
  border: solid 1px #ccc;
  background-color: rgb(39, 90, 167);
  color: white;
  font-size: 20px;
  font-weight: bold;
  line-height: 200px;
}
.flex {
  display: flex;
  justify-content: space-around;
}
</style>
<script>
import AgoraRTC from "agora-rtc-sdk";
import AgoraRTM from "agora-rtm-sdk";
import Sortable from "sortablejs";
import $ from "jquery";
var rtc = {
  client: null,
  screenClient: null,
  joined: false,
  published: false,
  localStream: null,
  remoteStreams: [],
  params: {},
  screenLocalStream: null,
  screenRemoteStreams: [],
  screenParams: {},
};
export default {
  name: "operationsAdd",
  data() {
    return {
      option: {
        token:
          "006b5f73fbb89f14aa58028aafb7c8822e0IADPU00wAtWTcKAZp2Co9B0PMzsYvl8IWcS3JbINhfoHCupuE8wAAAAAIgBkg7/NlF9hYQQAAQCUX2FhAgCUX2FhAwCUX2FhBACUX2Fh",
        channel: "testchannel",
        uid: 814041477,
        appID: "b5f73fbb89f14aa58028aafb7c8822e0",
      },
      value: [
        { id: 0, name: 11111, content: "" },
        { id: 1, name: 22222, content: "<p>22222</p>" },
        { id: 2, name: 33333, content: "<p>33333</p>" },
        { id: 3, name: 44444, content: "<p>44444</p>" },
      ],
    };
  },
  mounted() {
    this.createvideo();
    this.sortab();
  },
  methods: {
    testVideo() {
      // 获取所有音视频设备。
      console.log("getCameras",AgoraRTC.getCameras());
      AgoraRTC.getDevices()
        .then((devices) => {
          const audioDevices = devices.filter(function (device) {
            return device.kind === "audioinput";
          });
          const videoDevices = devices.filter(function (device) {
            return device.kind === "videoinput";
          });

          var selectedMicrophoneId = audioDevices[0].deviceId;
          var selectedCameraId = videoDevices[0].deviceId;
          return Promise.all([
            AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
            AgoraRTC.createMicrophoneAudioTrack({
              microphoneId: selectedMicrophoneId,
            }),
          ]);
        })
        .then(([videoTrack, audioTrack]) => {
          videoTrack.play("mic-test");
          setInterval(() => {
            const level = audioTrack.getVolumeLevel();
            console.log("local stream audio level", level);
          }, 1000);
        });
    },
    createvideo() {
      this.testVideo()
      let _this = this;
      // 开启日志上传功能
      AgoraRTC.Logger.enableLogUpload();
      // 将日志输出级别设置为 INFO
      AgoraRTC.Logger.setLogLevel(AgoraRTC.Logger.INFO);
      rtc.client = AgoraRTC.createClient({
        mode: "rtc",
        codec: "vp8",
      });

      //初始化
      rtc.client.init(
        _this.option.appID,
        function () {
          console.log("客户端初始化完成");
          //=======加入频道=======
          rtc.client.join(
            _this.option.token,
            _this.option.channel,
            _this.option.uid,
            function (uid) {
              rtc.params.uid = uid;
              // 切换本地发布流
              let id = rtc.params.uid;
              //角色为主播，发布本地流
              rtc.localStream = AgoraRTC.createStream({
                streamID: rtc.params.uid,
                audio: true,
                video: true,
                mirror: true,
                screen: false,
                optimizationMode: "detail",
                audioProcessing: {
                  AEC: true,
                  AGC: true,
                  ANS: true,
                },
              });
              rtc.localStream.init(function () {
                rtc.localStream.play("item0", { muted: true });
                console.log('$("#item0").html()', $("#item0").html());
                _this.value.forEach((element) => {
                  if (element.id === 0) {
                    element.content = $("#item0").html();
                  }
                });

                $(`#video${id}`).css("object-fit", "scale-down");
                //发布
                rtc.client.publish(rtc.localStream, function (err) {
                  _this.sendRmg("发布本地流失败！", "error");
                  console.error(err);
                });
              });
              // 监听token失效，修改直播状态
              rtc.client.on("token-privilege-did-expire", function () {});
            },
            function (err) {
              _this.sendRmg("加入频道失败", "error");
              console.log(err);
            }
          );
        },
        (err) => {
          this.$message.error("直播间初始化失败，请检查授权码是否正确");
          console.error("加入频道失败：", err);
        }
      );
    },
    // 发送信息
    sendRmg(msg, tpe) {
      this.$message({
        showClose: true,
        message: msg,
        type: tpe ? tpe : "info",
      });
    },
    sortab() {
      var that = this;
      var el = document.getElementById("items");
      // 常用
      new Sortable(el, {
        onEnd: function (evt) {
          // 获取排序之后的data数据
          that.value.splice(
            evt.newIndex,
            0,
            that.value.splice(evt.oldIndex, 1)[0]
          );
          var newArray = that.value.slice(0);
          that.value = [];
          that.$nextTick(function () {
            that.value = newArray;
            console.log(that.value);
          });
        },
      });
    },
    //离开直播间
    leaveLive(type) {
      var _this = this;
      if (rtc.client) {
        rtc.client.leave(
          function () {
            rtc.localStream.stop();
            rtc.localStream.close();
            while (rtc.remoteStreams.length > 0) {
              var stream = rtc.remoteStreams.shift();
              var id = stream.getId();
              stream.stop();
              stream.close();
              removeView(id);
            }
            console.log("离开频道成功");
          },
          function (err) {
            console.log("离开频道失败" + err);
          }
        );
      }
    },
  },
  beforeDestroy() {
    this.leaveLive();
  },
};
</script>
